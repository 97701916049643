export class StringHelper {
  public static pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  public static decodeBase64(str: string): string {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output: string = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }

    for (
      // initialize result and counters
      let bc: number = 0, bs: any, buffer: any, idx: number = 0;
      // get next character
      buffer = str.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }

    // ASCII to Unicode
    return decodeURIComponent(this.escape(output));
  }

  public static escapeHash(input: string): string {
    var escapeHash = {};
    var ret = escapeHash[input];

    if (!ret) {
      if (input.length - 1) {
        ret = String.fromCharCode(input.substring(input.length - 3 ? 2 : 1) as any);
      }
      else {
        var code = input.charCodeAt(0);
        ret = code < 256
          ? "%" + (0 + code.toString(16)).slice(-2).toUpperCase()
          : "%u" + ("000" + code.toString(16)).slice(-4).toUpperCase();
      }
      escapeHash[ret] = input;
      escapeHash[input] = ret;
    }

    return ret;
  }

  public static escape(str: string) {
    return str.replace(/[^\w @\*\-\+\.\/]/g, value => this.escapeHash(value));
  };

  public static unescape(str: string) {
    return str.replace(/%(u[\da-f]{4}|[\da-f]{2})/gi, value => this.escapeHash(value));
  };
}
